import React, { useEffect, useState } from 'react';
import * as api from '../api/api';
import { getLastUpdatedTimeWithUnit } from '../utils/TimeUtil';

const AuthContent = () => {
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [mostRecentActivityDate, setMostRecentActivityDate] = useState("Unknown");
    const [lastUpdated, setLastUpdated] = useState("Unknown");
    const [progressesByYears, setProgressesByYears] = useState([]);
    const [yearsVisible, setYearsVisible] = useState([]);

    const updateProgressState = (progresses, lastUpdated, firstRun = false) => {
        if (lastUpdated === 0) {
            setLastUpdated("Never");
        } else {
            setLastUpdated(getLastUpdatedTimeWithUnit(lastUpdated));
        }

        setMostRecentActivityDate(progresses.mostRecentActivityDate);
        const progressesMap = progresses?.goalsProgress?.map((progress, _) => {
            return getProgressProcessed(progress);
        });
        const progressesByYears = progressesMap?.reduce((acc, progress) => {
            const endDateRaw = progress["End Date"] ?? "";
            //minus one day to get the correct year
            const endDateEpoch = new Date(endDateRaw)
            endDateEpoch.setDate(endDateEpoch.getDate() - 1);
            const endDate = new Date(endDateEpoch).toISOString();
            const year = endDate.split("-")[0];
            if (!year) {
                return acc;
            }
            if (!acc[year]) {
                acc[year] = [];
            }
            acc[year].push(progress);
            return acc;
        }
        , {});
        setProgressesByYears(progressesByYears);
        if (firstRun && progressesByYears) {
            const yearsVisible = Object.keys(progressesByYears)?.map((year) => {
                return {
                    "year": year,
                    "visible": year >= new Date().getFullYear(),
                }
            });
            setYearsVisible(yearsVisible);
        }
    }

    const getProgressProcessed = (progress) => {
        const numberWithCommas = (num) => {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        const valueWithUnit = (value, unit) => {
            let proccessedUnit = unit;
            let proccessedValue = value ?? "0";
            if (proccessedUnit === "hours") {
                if (value < 1) {
                    proccessedValue = Math.round(value * 60, 1);
                    proccessedUnit = "minutes";
                }
            }
            return numberWithCommas(proccessedValue) + " " + proccessedUnit;
        }
        let unit = (progress?.unit) ?? "";
        let totalsText = ""
        if (progress.activityTypes.length > 0) {
            for (let i = 0; i < progress.activityTypes.length; i++) {
                const progressActivityType = progress.activityTypes[i];
                totalsText += progressActivityType.charAt(0).toUpperCase() + 
                    progressActivityType.slice(1).toLowerCase() + 
                    ": " + 
                    valueWithUnit(progress.progressByActivityType[i], unit) + 
                    "\n"
            }
        }
        return {
            "Goal": (progress?.goalName ?? ""),
            "Goal Category": (progress?.goalCategoryName ?? ""),
            "Start Date": (progress?.startDate ?? ""),
            "End Date": (progress?.endDate ?? ""),
            "Totals Text": totalsText,
            "Target": valueWithUnit(progress?.goalValue, unit),
            "Target average per day": valueWithUnit(progress?.targetAverage, unit),
            "So far": valueWithUnit(progress?.totalProgress, unit) + ", " + (progress?.percentageDone ?? "") + "%",
            "To go": valueWithUnit(progress?.left, unit) + ", " + (progress?.percentageLeft ?? "") + "%",
            "New average per day": valueWithUnit(progress?.newAverage, unit),
            "Pace difference": valueWithUnit(progress?.paceDifference, unit),
            "Percentage pace difference": (progress?.percentagePaceDifference ?? "") + "%",
            "percentageTimePassed": (progress?.percentageTimePassed ?? "") + "%",
        }
    }

    const getProgressFromServer = () => {
        setIsRefreshing(true);
        api.getProgress()
            .then(({data}) => {
                const progresses = data ?? "Failed to Load";
                const lastUpdated = Date.now();
                localStorage.setItem("progress", JSON.stringify({
                    "progresses": progresses,
                    "lastUpdated": lastUpdated,
                }));
                updateProgressState(progresses, lastUpdated)
                setIsRefreshing(false);
            }).catch((error) => {
                console.log(error);
                setIsRefreshing(false);
            });
    }

    const setRegistration = () => {
        const stravaUrl = "https://www.strava.com/oauth/authorize";
        const host = window.location.origin;
        const redirectUri = `${host}/register/strava`;
        const redirectUriEncoded = encodeURIComponent(redirectUri);
        const scope = "activity:read_all";
        const clientId = "44713";
        const registerationUri = `${stravaUrl}?client_id=${clientId}&redirect_uri=${redirectUriEncoded}&response_type=code&approval_prompt=auto&scope=${scope}`;
        window.location.href = registerationUri;
    }

    const getAllProgressFromServer = () => {
        setIsRefreshing(true);
        api.getAllProgress()
            .then(({data}) => {
                const progresses = data ?? "Failed to Load";
                const lastUpdated = Date.now()
                localStorage.setItem("progress", JSON.stringify({
                    "progresses": progresses,
                    "lastUpdated": lastUpdated
                }));
                updateProgressState(progresses, lastUpdated)
                setIsRefreshing(false);
            }).catch((error) => {
                console.log(error);
                setIsRefreshing(false);
            });
    }

    const getProgress = (firstRun = false) => {
        let progress;
        try {
            progress = JSON.parse(localStorage.getItem("progress"));
        } catch (e) {
            progress = null;
        }
        const progresses = progress?.progresses ?? "Loading";
        const lastUpdated = progress?.lastUpdated ?? 0;
        updateProgressState(progresses, lastUpdated, firstRun);
        const millisecondsInSecond = 1000;
        const secondsInMinute = 60;
        const minutesInHour = 60;
        const thresholdInMilliseconds = minutesInHour * secondsInMinute * millisecondsInSecond;
        const now = Date.now()
        if (!isRefreshing && firstRun && now - lastUpdated >= thresholdInMilliseconds) {
            getProgressFromServer();
        }
    }

    useEffect(() => {
        getProgress(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    setInterval(() => getProgress(false), 10 * 1000);

    return (
        <div
            style={{
                fontSize: "20px",
                display: 'flex',
                justifyContent: "center",
                alignItems: 'center',
                padding: "14px",
                height: '100vh',
                flexWrap: "wrap",
            }}
        >
            <button 
                style={{
                    fontSize: "28px",
                    marginBottom: "12px",
                    marginRight: "12px",
                    width: "100%",
                }}
                id="refresh_button" 
                onClick={getProgressFromServer}
                disabled={isRefreshing}
            >
                {isRefreshing ? "Refreshing" : "Refresh"}
            </button>
            <button
                style={{
                    fontSize: "28px",
                    marginBottom: "12px",
                    width: "100%",
                }}
                id="refresh_all_button"
                value="Refresh All"
                onClick={getAllProgressFromServer}
                disabled={isRefreshing}
            >
                {isRefreshing ? "Refreshing" : "Refresh All"}
            </button>
            <div
                style={{
                    fontSize: "26px",
                    width: "100%",
                    marginLeft: "20%",
                    marginRight: "20%",
                }}
            >
                {"Last Activity Date: "}
                <span style={{fontSize: "20px"}} id="most_recent_activity_date">
                    {mostRecentActivityDate}
                </span>
            </div>
            <span 
                style={{
                    fontSize: "26px",
                    width: "100%",
                    marginLeft: "20%",
                    marginRight: "20%",
                }}
            >
                Last Updated: 
                <span style={{fontSize: "20px"}} id="last_updated">
                    {lastUpdated}
                </span>
            </span>
            {Object.entries(progressesByYears).sort((a, b) => {
                return a[0] < b[0] ? 1 : -1;
            }).map(([year, progressesByYear]) => {
                return (
                    <div
                        key={year}
                        style={{
                            display: "flex",
                            padding: "10px",
                            margin: "10px",
                            width: "90%",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "20px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                fontSize: "20px",
                                padding: "10px",
                                margin: "10px",
                                width: "800px",
                                maxWidth: "90vw",
                                borderStyle: 'double',
                            }}
                        >
                            <h1
                                style={{
                                    fontSize: "24px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    const newYearsVisible = yearsVisible.map((yearVisible) => {
                                        return {
                                            "year": yearVisible.year,
                                            "visible": yearVisible.year === year ? !yearVisible.visible : yearVisible.visible,
                                        }
                                    });
                                    setYearsVisible(newYearsVisible);
                                }} 
                            >
                                {year}
                            </h1>
                            <div
                                style={{
                                    display: yearsVisible.find((yearVisible) => yearVisible.year === year).visible ? "block" : "none",
                                }}
                            >
                                {progressesByYear.map((progress, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                fontSize: "18px",
                                                whiteSpace: "pre-wrap",
                                                borderStyle: 'double',
                                                padding: '10px',
                                                margin: '10px',
                                                width: "80vw",
                                                maxWidth: "600px",
                                            }}
                                        >
                                            <h2
                                                style={{
                                                    fontSize: "20px",
                                                }}
                                            >
                                                {progress["Goal"]}
                                            </h2>
                                            <div
                                                style={{
                                                    fontSize: "20px",
                                                    display: progress["Goal Category"] === "" ? "none" : "block",
                                                }}
                                            >
                                                Goal Category: {progress["Goal Category"]}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "20px",
                                                }}
                                            >
                                                Start Date: {progress["Start Date"]}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "20px",
                                                }}
                                            >
                                                End Date: {progress["End Date"]}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "20px",
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                }}
                                            >
                                                {progress["Totals Text"]}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "20px",
                                                }}
                                            >
                                                Target: {progress["Target"]}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "20px",
                                                }}
                                            >
                                                So far: {progress["So far"]}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "20px",
                                                }}
                                            >
                                                To go: {progress["To go"]}
                                            </div>
                                            <div
                                                style={{
                                                    height: "24px",
                                                    width: "80vw",
                                                    maxWidth: "600px",
                                                    backgroundColor: "red",
                                                    position: "relative",
                                                    border: "1px solid black",
                                                }}
                                            >
                                                <div 
                                                    style={{
                                                        height: "24px",
                                                        width: progress["So far"].split(", ")[1],
                                                        backgroundColor: "darkgreen",
                                                        position: "absolute",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        height: "24px",
                                                        width: progress["percentageTimePassed"],
                                                        backgroundColor: "darkred",
                                                        position: "absolute",
                                                    }}
                                                />
                                                <div 
                                                    style={{
                                                        height: "24px",
                                                        width: Math.min(progress["So far"].split(", ")[1].split("%")[0], progress["percentageTimePassed"].split("%")[0]) + "%",
                                                        backgroundColor: "green",
                                                        position: "absolute",
                                                    }}
                                                />
                                                {/* <div
                                                    style={{
                                                        height: "24px",
                                                        width: Math.abs(progress["So far"].split(", ")[1].split("%")[0] 
                                                            - progress["percentageTimePassed"].split("%")[0]) + "%",
                                                        backgroundColor: "darkgreen",
                                                        position: "absolute",
                                                    }}   
                                                /> */}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "20px",
                                                }}
                                            >
                                                Target average per day: {progress["Target average per day"]}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "20px",
                                                }}
                                            >
                                                New average per day: {progress["New average per day"]}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "20px",
                                                    color: progress["Pace difference"].includes("-") ? "red" : "green",
                                                }}
                                            >
                                                Pace difference: {progress["Pace difference"]}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "20px",
                                                    color: progress["Percentage pace difference"].includes("-") ? "red" : "green",
                                                }}
                                            >
                                                Percentage pace difference: {progress["Percentage pace difference"]}
                                            </div>
                                        </div>
                                    );
                                })}
                                </div>
                        </div>
                    </div>
                );
            })}
            <img
                src={require("../images/btn_strava_connectwith_orange.png")}
                alt="Strava Connect"
                style={{
                    height: "96px",
                    cursor: "pointer",
                }}
                onClick={setRegistration}
            />
        </div>
    );
}

export default AuthContent;
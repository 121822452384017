import React, { useState } from 'react';

import './App.css';
import Login from './components/Login';
import AuthContent from './components/AuthContent';
import NonAuthContent from './components/NonAuthContent';
import RegisterStrava from './components/RegisterStrava';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("ServiceWorker.js").then(registration => {
          console.log("SW Registered!");
      }).catch(error => {
          console.log("SW Registration Failed");
      });
  } else {
      console.log("Not supported");
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <div>
            <Login
              setIsAuthenticated={setIsAuthenticated}
            />
            {isAuthenticated ? (
              <AuthContent/>
            ) : (
              <NonAuthContent/>
            )}
          </div>
        }>
        </Route>
          <Route path="register/strava" element={<RegisterStrava />} />
          <Route path="*" element={<div> No Page </div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

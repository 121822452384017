

export const getLastUpdatedTimeWithUnit = (lastUpdated) => {
    const lastUpdatedDate = new Date(lastUpdated);
    const milliseconds = Date.now() - lastUpdatedDate;
    const seconds = milliseconds / 1000;
    let time, unit;
    if (seconds < 2) {
        return " Just now";
    } else if (seconds < 60) {
        time = seconds;
        unit = "s";
    } else if (seconds < 3600) {
        time = seconds / 60
        unit = " min";
    } else if (seconds < 3600 * 24) {
        time = seconds / 3600
        unit = " hr";
    } else if (seconds < 3600 * 24 * 7) {
        time = seconds / (3600 * 24)
        unit = " day";
    } else {
        return " " + lastUpdatedDate.toLocaleDateString();
    }
    return " " + Math.floor(time).toString() +
        unit +
        ((time >= 2 && unit !== "s") ? "s" : "") + " ago";
}

import React, { useEffect, useState } from 'react';
import * as api from '../api/api';

const RegisterStrava = () => {
    useEffect(() => {
        const registerStrava = () => {
            const code = new URLSearchParams(window.location.search).get('code');
            return api.registerStrava(code);
        }
        console.log("Registering Strava");
        registerStrava().then(response => {

            console.log(response);
            window.location.href = window.location.origin;
        });

    }, []);

    return (
        <div>
            <h1>Registering</h1>
            <p>
                Redirecting...
            </p>
        </div>
    );
}

export default RegisterStrava;
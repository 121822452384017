import axios from 'axios';
import {getAuth} from 'firebase/auth'
import { jwtDecode } from "jwt-decode";

axios.interceptors.request.use(
  async (config) => {
      let accessToken = localStorage.getItem('accessToken');
      const refreshThresholdSeconds = 120;
      if (!accessToken || jwtDecode(accessToken).exp < (Date.now() / 1000) - refreshThresholdSeconds) {
        accessToken = null;
        const user = getAuth().currentUser;
        if (user) {
          accessToken = await user.getIdToken(true);
          localStorage.setItem('accessToken', accessToken);
        }
      }
      if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);

const BACKEND_API = process.env.REACT_APP_URI
  ? `${process.env.REACT_APP_URI}/api`
  : '/api';

export const getProgress = () =>
  axios.get(`${BACKEND_API}/progress`);

export const getAllProgress = () => 
  axios.get(`${BACKEND_API}/progress/all`);

export const registerStrava = (code) => {
  const stravaAuthUrl = `${BACKEND_API}/strava/client`;
  return axios.post(stravaAuthUrl, {
      code
    }
  );
}

